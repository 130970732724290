
// import { Highcharts } from '@/module/charts'

export default {
    chart: {
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        crosshair: {
            width:1,
        },
        type:'category',
        tickLength:5,
        tickWidth:1,
        tickInterval:5,
        labels : {
            autoRotation: [-10],
            formatter(){
                let arr = this.value.split('\n')
                let [date ,time] = arr
                let [day,month] = date.split('/')
                return `${day}/${month} ${time}`
            }
        }
    },
    yAxis: {
        min:0,
        crosshair: {
            width:1,
        },
        title: {
            text: ' '
        }
    },
    legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top'
    },
    series: []
}
