<template>
    <Spin :show="true" class="empty-card">
        <slot>
            <span>No data available</span>
        </slot>
    </Spin>
</template>

<script>
    import Spin from './Spin.vue'

    export default {
        name: "Empty",
        components: {Spin}
    }
</script>

<style lang="scss">
    .empty-card {

    }

</style>
