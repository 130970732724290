var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { attrs: { hover: "" } }, [
    _c("div", {
      ref: "chart",
      staticClass: "chart-cart-container",
      staticStyle: { width: "100%", height: "300px" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }