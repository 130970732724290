<template>
    <v-card class="m-tab-card " elevation="0" color="transparent">
        <v-card-title class="m-title font-weight-bold">{{title}}<slot name="title"></slot></v-card-title>
        <v-card-text>
            <slot></slot>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "TabCard",
        props : ['title']
    }
</script>

<style lang="scss">
    .m-tab-card{
        .m-title{
            display: inline-block;
            padding: 12px 16px;
            background-color:#fff;
            border-radius: 8px 8px 0 0;
            color: #3f51b5;
        }
        .v-card__text{
            background-color: #fff;
        }


    }



</style>