var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "m-tab-card ",
      attrs: { elevation: "0", color: "transparent" }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "m-title font-weight-bold" },
        [_vm._v(_vm._s(_vm.title)), _vm._t("title")],
        2
      ),
      _c("v-card-text", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }