<template>
    <v-container class="panel-wrapper panel-metrics pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Metrics">
                    <v-layout wrap class="search-wrapper">
                        <v-flex style="width: 280px;" shrink>
                            <v-select
                                    class="filter"
                                    v-model="selectedNode"
                                    @change="changeCondition"
                                    :disabled="loading"
                                    :items="nodeList"
                                    return-object
                                    item-text="name"
                                    solo
                                    hide-details
                                    dense>
                            </v-select>
                        </v-flex>
                        <v-flex style="width: 200px;" shrink>
                            <v-select
                                    class="filter"
                                    v-model="selectedTime"
                                    :disabled="loading"
                                    @change="changeCondition"
                                    return-object
                                    item-text="text"
                                    :items="timeList"
                                    solo hide-details dense></v-select>
                        </v-flex>
                        <v-flex shrink>
                            <v-btn color="accent" icon   width="40" :disabled="loading"  style="margin: 0;"
                                   @click="refresh">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap style="margin-top: 20px;">
                        <template v-if="loading">
                            <v-flex xs12 style="height: 384px;">
                                <Spin></Spin>
                            </v-flex>
                        </template>
                        <template v-if="!loading && chartList.length>0">
                            <v-flex xs12 sm12 md12 lg6 v-for="(chartData, i) in chartList" :key="i">
                                <ChartCart :chart-data="chartData"></ChartCart>
                            </v-flex>
                        </template>
                        <template v-if="!loading && chartList.length<=0">
                            <v-flex xs12>
                                <div class="my-3" style="min-height: 130px;">
                                    <Empty></Empty>
                                </div>
                            </v-flex>
                        </template>
                    </v-layout>
                    <v-layout style="margin-top: 20px;">
                        <v-spacer></v-spacer>
                        <v-flex>
                            <v-pagination
                                    :disabled="loading"
                                    @input="changePage"
                                    v-model="page"
                                    :length="total"
                            ></v-pagination>
                        </v-flex>
                    </v-layout>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import ChartCart from './_source/ChartCart.vue'
    import Empty from '@/components/Empty.vue'
    import Spin from '@/components/Spin.vue'
    import TabCard from '@/components/TabCard.vue'
    import {mapState, mapGetters} from 'vuex'


    export default {
        name: "Metrics",
        components: {ChartCart, Spin, Empty, TabCard},
        data() {
            return {
                page: 1,
                total: 0,
                loading: false,
                selectedNode: {},
                selectedTime: {},
                chartList: []
            }
        },
        computed: {
            nodeList() {
                return this.stats.instance_status
            },
            timeList() {
                return [
                    {text: 'Last Hour', value: '1h'},
                    {text: 'Last 3 Hours', value: '3h'},
                    {text: 'Last 6 Hours', value: '6h'},
                    {text: 'Last 12 Hours', value: '12h'},
                    {text: 'Last 24 Hours', value: '24h'},
                    {text: 'Last 3 Days', value: '3d'},
                    {text: 'Last 1 Week', value: '1w'},
                ]
            },
            ...mapState('application', ['appInfo', 'stats']),
            ...mapGetters('application', ['getServiceId'])
        },
        methods: {
            changeCondition() {
                this.getMetrics(1)
            },
            refresh() {
                this.getMetrics()
            },
            changePage(page) {
                this.getMetrics(page)
            },
            getMetrics(page) {
                if (this.loading) {
                    return
                }
                if (page) {
                    this.page = page
                }
                this.loading = true
                this.$http.get(`/metrics/`, {
                    params: {
                        page: this.page,
                        service_uuid: this.getServiceId(this.appInfo),
                        page_size: 4,
                        time: this.selectedTime.value,
                        node: this.selectedNode.name
                    }
                }).then((res) => {
                    this.total = Math.round(res.total)
                    this.page = parseInt(res.page)
                    let list = res.results
                    this.chartList = list
                    this.loading = false
                }).catch(() => {
                    this.$message.error("Oops, it failed to retrieve metric information. Please contact us at support@cloudclusters.io for this issue.")
                    this.chartList = []
                    this.loading = false
                })
            }
        },
        created() {
            this.selectedNode = this.stats.instance_status[0]
            this.selectedTime = this.timeList[0]
            this.getMetrics(1)
            this.$SDK.track({pageName:'Metrics',productName:this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">
    .panel-metrics {
        .search-wrapper .v-text-field--solo .v-input__control {
            min-height: 36px;
        }
        .filter .v-select__selections .v-select__selection {
            display: inline-block;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

</style>
