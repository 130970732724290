<template>
    <v-card hover>
        <div  ref="chart" class="chart-cart-container" style="width: 100%;height: 300px;"></div>
    </v-card>
</template>

<script>
    import { Highcharts } from '@/module/charts'
    import defaultOPtions  from '@/module/charts/pubOption'
    import $ from 'jquery'

    export default {
        name: "ChartCart",
        props: ['chartData'],
        watch: {
            chartData : {
                deep : true,
                handler (){
                    this.renderChart()
                }
            }
        },
        methods: {
            renderChart(){
                Highcharts.chart( this.$refs.chart, $.extend( true ,{},defaultOPtions,{
                    title: {
                        text: this.chartData.title
                    },
                    subtitle: {
                        text: '' //this.chartData.key
                    },
                    xAxis: {
                        categories: this.chartData.time_data
                    },
                    series: this.chartData.data.map(o => o.value_data)
                }));
            }
        },
        mounted(){
            this.renderChart()
        },
        created(){
        }
    }
</script>

<style lang="scss">

</style>
