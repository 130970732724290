import Highcharts from 'highcharts/highcharts'
import theme from './theme'

Highcharts.setOptions({
    credits: {
        enabled: false
    },
    ...theme
})



export {
    Highcharts
}
